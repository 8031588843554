// App.js - WEB
import React, { Component } from "react";
import { View } from "react-native";
import firebase from 'firebase'
import { connect } from 'react-firebase'

import WebRoutesGenerator from "../../components/src/NativeWebRouteWrapper";
import { ModalContainer } from "react-router-modal";
import HomeScreen from "../../components/src/HomeScreen";
import TopNav from "../../components/src/TopNav";

import InfoPage from '../../blocks/info-page/src/InfoPageBlock'
import AlertBlock from '../../blocks/alert/src/AlertBlock.web'
import VisualAnalytics from "../../blocks/visualanalytics/src/VisualAnalytics";
import CustomisableUserProfiles from "../../blocks/CustomisableUserProfiles/src/CustomisableUserProfiles";
import VideoLibrary from "../../blocks/VideoLibrary/src/VideoLibrary";
import Ordermanagement from "../../blocks/ordermanagement/src/Ordermanagement";
import OrderDetails from "../../blocks/ordermanagement/src/OrderDetails";
import TaskAllocator from "../../blocks/TaskAllocator/src/TaskAllocator";
import FeedbackCollection from "../../blocks/FeedbackCollection/src/FeedbackCollection";
import CarouselDisplay from "../../blocks/carouseldisplay/src/CarouselDisplay";
import SocialMediaAccountLoginScreen from "../../blocks/social-media-account-login/src/SocialMediaAccountLoginScreen";
import SaveAsPdf from "../../blocks/saveaspdf/src/SaveAsPdf";
import ImportExportData from "../../blocks/importexportdata/src/ImportExportData";
import QuestionBank from "../../blocks/QuestionBank/src/QuestionBank";
import OrganisationHierarchy from "../../blocks/OrganisationHierarchy/src/OrganisationHierarchy";
import MobileAccountLoginBlock from "../../blocks/mobile-account-login/src/MobileAccountLoginBlock";
import DocumentDistribution from "../../blocks/documentdistribution/src/DocumentDistribution";
import ShoppingCartOrders from "../../blocks/shoppingcart/src/ShoppingCartOrders";
import AddShoppingCartOrderItem from "../../blocks/shoppingcart/src/AddShoppingCartOrderItem";
import Maps from "../../blocks/maps/src/Maps";
import AdvancedSearch from "../../blocks/advancedsearch/src/AdvancedSearch";
import CentralisedBilling from "../../blocks/CentralisedBilling/src/CentralisedBilling";
import Download from "../../blocks/Download/src/Download";
import CustomSideMenuIntegration2 from "../../blocks/CustomSideMenuIntegration2/src/CustomSideMenuIntegration2";
import OTPInputAuth from "../../blocks/otp-input-confirmation/src/OTPInputAuth";
import AutomatedEmailSending from "../../blocks/AutomatedEmailSending/src/AutomatedEmailSending";
import LocationbasedAlerts from "../../blocks/LocationbasedAlerts/src/LocationbasedAlerts";
import AccountScoreranking from "../../blocks/AccountScoreranking/src/AccountScoreranking";
import Payments from "../../blocks/Payments/src/Payments";
import CustomWidget3 from "../../blocks/CustomWidget3/src/CustomWidget3";
import InvoiceBilling from "../../blocks/InvoiceBilling/src/InvoiceBilling";
import Videos from "../../blocks/videos/src/Videos";
import CheckinModeApi from "../../blocks/CheckinModeApi/src/CheckinModeApi";
import SeparateWebDeploymentWithAdminIntegration2 from "../../blocks/SeparateWebDeploymentWithAdminIntegration2/src/SeparateWebDeploymentWithAdminIntegration2";
import PaymentAdmin from "../../blocks/PaymentAdmin/src/PaymentAdmin";
import FullTeamVisibility2 from "../../blocks/FullTeamVisibility2/src/FullTeamVisibility2";
import RequestManagement from "../../blocks/RequestManagement/src/RequestManagement";
import SeparateMobileDevelopment2 from "../../blocks/SeparateMobileDevelopment2/src/SeparateMobileDevelopment2";
import PeopleManagement2 from "../../blocks/PeopleManagement2/src/PeopleManagement2";
import Geofence from "../../blocks/geofence/src/Geofence";
import Customform from "../../blocks/customform/src/Customform";
import NavigationMenu from "../../blocks/navigationmenu/src/NavigationMenu";
import EmailServerConfiguration3 from "../../blocks/EmailServerConfiguration3/src/EmailServerConfiguration3";
import Pushnotifications from "../../blocks/pushnotifications/src/Pushnotifications";
import ForgotPassword from "../../blocks/forgot-password/src/ForgotPassword";
import ForgotPasswordOTP from "../../blocks/forgot-password/src/ForgotPasswordOTP";
import NewPassword from "../../blocks/forgot-password/src/NewPassword";
import ActivityFeed from "../../blocks/ActivityFeed/src/ActivityFeed";
import Notifications from "../../blocks/notifications/src/Notifications";
import ServiceSpecificSettingsAdmin from "../../blocks/ServiceSpecificSettingsAdmin/src/ServiceSpecificSettingsAdmin";
import Addresses from "../../blocks/addressmanagement/src/Addresses";
import AddAddress from "../../blocks/addressmanagement/src/AddAddress";
import Crm3rdPartyIntegration from "../../blocks/Crm3rdPartyIntegration/src/Crm3rdPartyIntegration";
import DocumentOpener from "../../blocks/documentopener/src/DocumentOpener";
import EmailLists from "../../blocks/EmailLists/src/EmailLists";
import Analytics from "../../blocks/analytics/src/Analytics";
import EmergencySos from "../../blocks/EmergencySos/src/EmergencySos";
import Filteritems from "../../blocks/filteritems/src/Filteritems";
import Filteroptions from "../../blocks/filteritems/src/Filteroptions";
import ItemAvailability from "../../blocks/ItemAvailability/src/ItemAvailability";
import RefundManagement from "../../blocks/RefundManagement/src/RefundManagement";
import FormApprovalWorkflow from "../../blocks/FormApprovalWorkflow/src/FormApprovalWorkflow";
import Referrals from "../../blocks/Referrals/src/Referrals";
import AuditTrail from "../../blocks/AuditTrail/src/AuditTrail";
import ImpersonateUser3 from "../../blocks/ImpersonateUser3/src/ImpersonateUser3";
import KanbanBoard from "../../blocks/KanbanBoard/src/KanbanBoard";
import Appointments from "../../blocks/appointmentmanagement/src/Appointments";
import AddAppointment from "../../blocks/appointmentmanagement/src/AddAppointment";
import UserProfileBasicBlock from "../../blocks/user-profile-basic/src/UserProfileBasicBlock";
import UserGroups2 from "../../blocks/UserGroups2/src/UserGroups2";
import BulkUploading from "../../blocks/BulkUploading/src/BulkUploading";
import Scheduling from "../../blocks/scheduling/src/Scheduling";
import Categoriessubcategories from "../../blocks/categoriessubcategories/src/Categoriessubcategories";
import ContentManagement from "../../blocks/ContentManagement/src/ContentManagement";
import CountryCodeSelector from "../../blocks/country-code-selector/src/CountryCodeSelector";
import ReviewAndApproval from "../../blocks/ReviewAndApproval/src/ReviewAndApproval";
import DataEncryption from "../../blocks/DataEncryption/src/DataEncryption";
import Share from "../../blocks/share/src/Share";
import GpstrackerIntegration2 from "../../blocks/GpstrackerIntegration2/src/GpstrackerIntegration2";
import Blockedusers from "../../blocks/blockedusers/src/Blockedusers";
import AddBlockeduser from "../../blocks/blockedusers/src/AddBlockeduser";
import LeadManagement from "../../blocks/LeadManagement/src/LeadManagement";
import CustomLogoIntegration3 from "../../blocks/CustomLogoIntegration3/src/CustomLogoIntegration3";
import ContactsList from "../../blocks/ContactsList/src/ContactsList";
import FeatureSettings2 from "../../blocks/FeatureSettings2/src/FeatureSettings2";
import PhoneNumberInput from "../../blocks/mobile-account-registration/src/PhoneNumberInput";
import AdditionalDetailForm from "../../blocks/mobile-account-registration/src/AdditionalDetailForm";
import RolesPermissions from "../../blocks/RolesPermissions/src/RolesPermissions";
import CustomizablePortalIntegration4 from "../../blocks/CustomizablePortalIntegration4/src/CustomizablePortalIntegration4";
import SocialMediaAccountRegistrationScreen from "../../blocks/social-media-account-registration/src/SocialMediaAccountRegistrationScreen";
import AutomaticReminders from "../../blocks/AutomaticReminders/src/AutomaticReminders";
import Sorting from "../../blocks/sorting/src/Sorting";
import Reservations from "../../blocks/Reservations/src/Reservations";
import MemberDirectory2 from "../../blocks/MemberDirectory2/src/MemberDirectory2";
import Location from "../../blocks/location/src/Location";
import AdminConsole from "../../blocks/AdminConsole/src/AdminConsole";
import ApiIntegration from "../../blocks/apiintegration/src/ApiIntegration";
import Contactus from "../../blocks/contactus/src/Contactus";
import AddContactus from "../../blocks/contactus/src/AddContactus";
import Catalogue from "../../blocks/catalogue/src/Catalogue";
import EducationalUserProfile from "../../blocks/educational-user-profile/src/EducationalUserProfile";
import Notificationsettings from "../../blocks/notificationsettings/src/Notificationsettings";
import EmailAccountRegistration from "../../blocks/email-account-registration/src/EmailAccountRegistration";
import Dashboard from "../../blocks/dashboard/src/Dashboard";
import Splashscreen from "../../blocks/splashscreen/src/Splashscreen";
import EmailNotifications2 from "../../blocks/EmailNotifications2/src/EmailNotifications2";
import FileAttachment from "../../blocks/FileAttachment/src/FileAttachment";
import CustomEventCapturing3 from "../../blocks/CustomEventCapturing3/src/CustomEventCapturing3";
import LeaveTracker from "../../blocks/LeaveTracker/src/LeaveTracker";
import ElasticSearch from "../../blocks/ElasticSearch/src/ElasticSearch";
import AdManager from "../../blocks/AdManager/src/AdManager";
import EmailAccountLoginBlock from "../../blocks/email-account-login/src/EmailAccountLoginBlock";
import InventoryManagement3 from "../../blocks/InventoryManagement3/src/InventoryManagement3";
import VideoManagement from "../../blocks/VideoManagement/src/VideoManagement";
import DragDropInterface from "../../blocks/dragdropinterface/src/DragDropInterface";
import TabletSupport64 from "../../blocks/TabletSupport64/src/TabletSupport64";
import LandingPage from "../../blocks/landingpage/src/LandingPage";
import CustomisedOrderStatus from "../../blocks/CustomisedOrderStatus/src/CustomisedOrderStatus";
import Archive from "../../blocks/Archive/src/Archive";
import PhotoLibrary3 from "../../blocks/PhotoLibrary3/src/PhotoLibrary3";
import TaskList from "../../blocks/TaskList/src/TaskList";
import TimesheetManagement from "../../blocks/TimesheetManagement/src/TimesheetManagement";
import ProjectNotes from "../../blocks/ProjectNotes/src/ProjectNotes";



const routeMap = {
VisualAnalytics:{
 component:VisualAnalytics,
path:"/VisualAnalytics"},
CustomisableUserProfiles:{
 component:CustomisableUserProfiles,
path:"/CustomisableUserProfiles"},
VideoLibrary:{
 component:VideoLibrary,
path:"/VideoLibrary"},
Ordermanagement:{
 component:Ordermanagement,
path:"/Ordermanagement"},
OrderDetails:{
 component:OrderDetails,
path:"/OrderDetails"},
TaskAllocator:{
 component:TaskAllocator,
path:"/TaskAllocator"},
FeedbackCollection:{
 component:FeedbackCollection,
path:"/FeedbackCollection"},
CarouselDisplay:{
 component:CarouselDisplay,
path:"/CarouselDisplay"},
SocialMediaAccountLoginScreen:{
 component:SocialMediaAccountLoginScreen,
path:"/SocialMediaAccountLoginScreen"},
SaveAsPdf:{
 component:SaveAsPdf,
path:"/SaveAsPdf"},
ImportExportData:{
 component:ImportExportData,
path:"/ImportExportData"},
QuestionBank:{
 component:QuestionBank,
path:"/QuestionBank"},
OrganisationHierarchy:{
 component:OrganisationHierarchy,
path:"/OrganisationHierarchy"},
MobileAccountLoginBlock:{
 component:MobileAccountLoginBlock,
path:"/MobileAccountLoginBlock"},
DocumentDistribution:{
 component:DocumentDistribution,
path:"/DocumentDistribution"},
ShoppingCartOrders:{
 component:ShoppingCartOrders,
path:"/ShoppingCartOrders"},
AddShoppingCartOrderItem:{
 component:AddShoppingCartOrderItem,
path:"/AddShoppingCartOrderItem"},
Maps:{
 component:Maps,
path:"/Maps"},
AdvancedSearch:{
 component:AdvancedSearch,
path:"/AdvancedSearch"},
CentralisedBilling:{
 component:CentralisedBilling,
path:"/CentralisedBilling"},
Download:{
 component:Download,
path:"/Download"},
CustomSideMenuIntegration2:{
 component:CustomSideMenuIntegration2,
path:"/CustomSideMenuIntegration2"},
OTPInputAuth:{
 component:OTPInputAuth,
path:"/OTPInputAuth"},
AutomatedEmailSending:{
 component:AutomatedEmailSending,
path:"/AutomatedEmailSending"},
LocationbasedAlerts:{
 component:LocationbasedAlerts,
path:"/LocationbasedAlerts"},
AccountScoreranking:{
 component:AccountScoreranking,
path:"/AccountScoreranking"},
Payments:{
 component:Payments,
path:"/Payments"},
CustomWidget3:{
 component:CustomWidget3,
path:"/CustomWidget3"},
InvoiceBilling:{
 component:InvoiceBilling,
path:"/InvoiceBilling"},
Videos:{
 component:Videos,
path:"/Videos"},
CheckinModeApi:{
 component:CheckinModeApi,
path:"/CheckinModeApi"},
SeparateWebDeploymentWithAdminIntegration2:{
 component:SeparateWebDeploymentWithAdminIntegration2,
path:"/SeparateWebDeploymentWithAdminIntegration2"},
PaymentAdmin:{
 component:PaymentAdmin,
path:"/PaymentAdmin"},
FullTeamVisibility2:{
 component:FullTeamVisibility2,
path:"/FullTeamVisibility2"},
RequestManagement:{
 component:RequestManagement,
path:"/RequestManagement"},
SeparateMobileDevelopment2:{
 component:SeparateMobileDevelopment2,
path:"/SeparateMobileDevelopment2"},
PeopleManagement2:{
 component:PeopleManagement2,
path:"/PeopleManagement2"},
Geofence:{
 component:Geofence,
path:"/Geofence"},
Customform:{
 component:Customform,
path:"/Customform"},
NavigationMenu:{
 component:NavigationMenu,
path:"/NavigationMenu"},
EmailServerConfiguration3:{
 component:EmailServerConfiguration3,
path:"/EmailServerConfiguration3"},
Pushnotifications:{
 component:Pushnotifications,
path:"/Pushnotifications"},
ForgotPassword:{
 component:ForgotPassword,
path:"/ForgotPassword"},
ForgotPasswordOTP:{
 component:ForgotPasswordOTP,
path:"/ForgotPasswordOTP"},
NewPassword:{
 component:NewPassword,
path:"/NewPassword"},
ActivityFeed:{
 component:ActivityFeed,
path:"/ActivityFeed"},
Notifications:{
 component:Notifications,
path:"/Notifications"},
ServiceSpecificSettingsAdmin:{
 component:ServiceSpecificSettingsAdmin,
path:"/ServiceSpecificSettingsAdmin"},
Addresses:{
 component:Addresses,
path:"/Addresses"},
AddAddress:{
 component:AddAddress,
path:"/AddAddress"},
Crm3rdPartyIntegration:{
 component:Crm3rdPartyIntegration,
path:"/Crm3rdPartyIntegration"},
DocumentOpener:{
 component:DocumentOpener,
path:"/DocumentOpener"},
EmailLists:{
 component:EmailLists,
path:"/EmailLists"},
Analytics:{
 component:Analytics,
path:"/Analytics"},
EmergencySos:{
 component:EmergencySos,
path:"/EmergencySos"},
Filteritems:{
 component:Filteritems,
path:"/Filteritems"},
Filteroptions:{
 component:Filteroptions,
path:"/Filteroptions"},
ItemAvailability:{
 component:ItemAvailability,
path:"/ItemAvailability"},
RefundManagement:{
 component:RefundManagement,
path:"/RefundManagement"},
FormApprovalWorkflow:{
 component:FormApprovalWorkflow,
path:"/FormApprovalWorkflow"},
Referrals:{
 component:Referrals,
path:"/Referrals"},
AuditTrail:{
 component:AuditTrail,
path:"/AuditTrail"},
ImpersonateUser3:{
 component:ImpersonateUser3,
path:"/ImpersonateUser3"},
KanbanBoard:{
 component:KanbanBoard,
path:"/KanbanBoard"},
Appointments:{
 component:Appointments,
path:"/Appointments"},
AddAppointment:{
 component:AddAppointment,
path:"/AddAppointment"},
UserProfileBasicBlock:{
 component:UserProfileBasicBlock,
path:"/UserProfileBasicBlock"},
UserGroups2:{
 component:UserGroups2,
path:"/UserGroups2"},
BulkUploading:{
 component:BulkUploading,
path:"/BulkUploading"},
Scheduling:{
 component:Scheduling,
path:"/Scheduling"},
Categoriessubcategories:{
 component:Categoriessubcategories,
path:"/Categoriessubcategories"},
ContentManagement:{
 component:ContentManagement,
path:"/ContentManagement"},
CountryCodeSelector:{
 component:CountryCodeSelector,
path:"/CountryCodeSelector"},
ReviewAndApproval:{
 component:ReviewAndApproval,
path:"/ReviewAndApproval"},
DataEncryption:{
 component:DataEncryption,
path:"/DataEncryption"},
Share:{
 component:Share,
path:"/Share"},
GpstrackerIntegration2:{
 component:GpstrackerIntegration2,
path:"/GpstrackerIntegration2"},
Blockedusers:{
 component:Blockedusers,
path:"/Blockedusers"},
AddBlockeduser:{
 component:AddBlockeduser,
path:"/AddBlockeduser"},
LeadManagement:{
 component:LeadManagement,
path:"/LeadManagement"},
CustomLogoIntegration3:{
 component:CustomLogoIntegration3,
path:"/CustomLogoIntegration3"},
ContactsList:{
 component:ContactsList,
path:"/ContactsList"},
FeatureSettings2:{
 component:FeatureSettings2,
path:"/FeatureSettings2"},
PhoneNumberInput:{
 component:PhoneNumberInput,
path:"/PhoneNumberInput"},
AdditionalDetailForm:{
 component:AdditionalDetailForm,
path:"/AdditionalDetailForm"},
RolesPermissions:{
 component:RolesPermissions,
path:"/RolesPermissions"},
CustomizablePortalIntegration4:{
 component:CustomizablePortalIntegration4,
path:"/CustomizablePortalIntegration4"},
SocialMediaAccountRegistrationScreen:{
 component:SocialMediaAccountRegistrationScreen,
path:"/SocialMediaAccountRegistrationScreen"},
AutomaticReminders:{
 component:AutomaticReminders,
path:"/AutomaticReminders"},
Sorting:{
 component:Sorting,
path:"/Sorting"},
Reservations:{
 component:Reservations,
path:"/Reservations"},
MemberDirectory2:{
 component:MemberDirectory2,
path:"/MemberDirectory2"},
Location:{
 component:Location,
path:"/Location"},
AdminConsole:{
 component:AdminConsole,
path:"/AdminConsole"},
ApiIntegration:{
 component:ApiIntegration,
path:"/ApiIntegration"},
Contactus:{
 component:Contactus,
path:"/Contactus"},
AddContactus:{
 component:AddContactus,
path:"/AddContactus"},
Catalogue:{
 component:Catalogue,
path:"/Catalogue"},
EducationalUserProfile:{
 component:EducationalUserProfile,
path:"/EducationalUserProfile"},
Notificationsettings:{
 component:Notificationsettings,
path:"/Notificationsettings"},
EmailAccountRegistration:{
 component:EmailAccountRegistration,
path:"/EmailAccountRegistration"},
Dashboard:{
 component:Dashboard,
path:"/Dashboard"},
Splashscreen:{
 component:Splashscreen,
path:"/Splashscreen"},
EmailNotifications2:{
 component:EmailNotifications2,
path:"/EmailNotifications2"},
FileAttachment:{
 component:FileAttachment,
path:"/FileAttachment"},
CustomEventCapturing3:{
 component:CustomEventCapturing3,
path:"/CustomEventCapturing3"},
LeaveTracker:{
 component:LeaveTracker,
path:"/LeaveTracker"},
ElasticSearch:{
 component:ElasticSearch,
path:"/ElasticSearch"},
AdManager:{
 component:AdManager,
path:"/AdManager"},
EmailAccountLoginBlock:{
 component:EmailAccountLoginBlock,
path:"/EmailAccountLoginBlock"},
InventoryManagement3:{
 component:InventoryManagement3,
path:"/InventoryManagement3"},
VideoManagement:{
 component:VideoManagement,
path:"/VideoManagement"},
DragDropInterface:{
 component:DragDropInterface,
path:"/DragDropInterface"},
TabletSupport64:{
 component:TabletSupport64,
path:"/TabletSupport64"},
LandingPage:{
 component:LandingPage,
path:"/LandingPage"},
CustomisedOrderStatus:{
 component:CustomisedOrderStatus,
path:"/CustomisedOrderStatus"},
Archive:{
 component:Archive,
path:"/Archive"},
PhotoLibrary3:{
 component:PhotoLibrary3,
path:"/PhotoLibrary3"},
TaskList:{
 component:TaskList,
path:"/TaskList"},
TimesheetManagement:{
 component:TimesheetManagement,
path:"/TimesheetManagement"},
ProjectNotes:{
 component:ProjectNotes,
path:"/ProjectNotes"},

  Home: {
component:Appointments,
    path: '/',
    exact: true
  },
  InfoPage: {
    component: InfoPage,
    path: '/InfoPage'
  },

  AlertWeb: {
    component: AlertBlock,
    path: "*/AlertWeb",
    modal: true
  }

};

const firebaseAPI = firebase.initializeApp({
  apiKey: "AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4",
  authDomain: "rnmasterapp-c11e9.firebaseapp.com",
  databaseURL: "https://rnmasterapp-c11e9.firebaseio.com",
  projectId: "rnmasterapp-c11e9",
  storageBucket: "rnmasterapp-c11e9.appspot.com",
  messagingSenderId: "649592030497",
  appId: "1:649592030497:web:7728bee3f2baef208daa60",
  measurementId: "G-FYBCF3Z2W3"
});

class App extends Component {
   
  render() {

    const defaultAnalytics = firebaseAPI.analytics();
    defaultAnalytics.logEvent('APP_Loaded');
    
    return (
      <View style={{ height: '100vh', width: '100vw' }}>
        <TopNav />
        {WebRoutesGenerator({ routeMap })}
        <ModalContainer />
      </View>
    );
  }
}

export default App;
